.radio-button-field {
  cursor: pointer;
}

.radio-button-field input{
  display: none
}

.instant-card-wrapper {
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 11px;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.6);
  -webkit-transition: background-color 500ms ease, -webkit-transform 300ms ease;
  transition: background-color 500ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, background-color 500ms ease;
  transition: transform 300ms ease, background-color 500ms ease,
    -webkit-transform 300ms ease;
  text-align: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
}

.instant-site-card-thumbnail {
  width: 100%;
  height: 125px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://assets.website-files.com/5ea6357d7899692584d2b70b/5ed74563f8c57fcb92b37ba5_New%20HVAC%20Hero%20No%20Nav.png);
}

.instant-card-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #333;
}

.instant-card-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

/* input:checked~.instant-card-wrapper .instant-card-text {
  color: white;
} */

input:checked~.instant-card-wrapper .check-badge {
  display: block;
}

.check-badge {
  position: relative;
  left: -30px;
  top: -30px;
  display: none;
  width: 60px;
  height: 60px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check {
  position: relative;
  left: 19px;
  top: 20px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
}

@media screen and (max-width: 930px) and (min-width: 577px) {
  .instant-site-card-thumbnail {
      height: 175px;
  }
}

