.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.main-container {
  width: 860px;
  margin-bottom: 30px;
}


h1 {
  font-family: 'Bitter', sans-serif;
  font-weight: 700;
  text-align: center;
}
h2 {
  font-family: 'Bitter', sans-serif;
  font-weight: 600;
  margin-bottom: 25px;
}

.text-fields-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 48px;
  row-gap: 24px;
}

.instant-step-text {
  /* margin-top: 40px; */
  margin-bottom: 20px;
  margin-left: -20px;
  color: #818181;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: left;
}

.template-selection {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
}

.create-website-button {
  -webkit-appearance: button;
  color: white;
  background-color: #146fd6;
  text-decoration: none;
  padding: 16px 80px;
  font-weight: 600;
  border-radius: 12px;
  outline: none;
  border: none;
  font-size: 16px;
}
.create-website-button:hover {
  background-color: #1058ab;
}
.create-website-button:active {
  background-color: #08366b;
}

.create-website-button:disabled {
  background-color: #818181;
}

#business-name {
  grid-column: span 4;
}

.create-site-section span {
  font-weight: 600;
}

.site-link {
  /* text-decoration: none; */
  color: #2388da;
  font-weight: 500
}

.reset-button {
  -webkit-appearance: button;
  color: white;
  background-color: #13c7d8;
  text-decoration: none;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 12px;
  outline: none;
  border: none;
  font-size: 16px;
}

@media screen and (max-width: 930px) {
  .main-container {
    width: 90%
  }

  .template-selection {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .template-selection {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;
  }

  .text-fields-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }

  .instant-step-text {
    margin-left: 0px
  }

  .create-website-button {
    padding: 12px 30px;
    font-size: 14px
  }

  #business-name {
    grid-column: span 2;
  }
}